import React, { useMemo, useEffect, useRef, useContext, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { installViewer } from '@evodicom/ohif-viewer';
import { useQueryClient } from '@tanstack/react-query';
import html2canvas from 'html2canvas';
import { Box } from '@mui/material';
import If from '@components/If';
import SoftLoading from '@components/SoftLoading';
import useConfirm from '@core/hooks/useConfirm';
import useGeneratedReports, { GENERATED_REPORTS_QUERY_KEY } from '@core/hooks/useGeneratedReports';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import { ThemeContext } from '@core/providers/ThemeProvider';
import ViewerInfoCommand from '@core/types/ViewerInfoCommand';
import { hideStartLoader } from '@core/utils/startLoader';
import Form from 'app/features/reports/add/components/Form';
import getPrintableReport from '../../api/getPrintableReport';
import useGetReportsCreatedByUser from '../../hooks/useGetReportsCreatedByUser';
import useViewerSideReportFunctionality from '../../hooks/useViewerSideReportFunctionality';
import config from './config';
import deleteSeriesHandler from './deleteSeriesHandler';
import useSx from './sx';

export interface CommandArguments {
  commandName: ViewerInfoCommand;
}

interface PdfLoadedEventData {
  pdfUrl: string;
  sopInstanceUID: string;
}

interface ReportEventData {
  studyId: string;
  seriesId: string;
  objectId: string;
}

export interface ViewerProps {
  studyInstanceUid: string;
  readOnly?: boolean;
  isAnon: boolean;
  isAdministrator: boolean;
  baseUrl: string;
  onCommandClick?: (commandArgs: CommandArguments) => void;
}

const containerId = 'ohif-viewer';

const Viewer = ({
  studyInstanceUid,
  readOnly,
  baseUrl,
  isAnon,
  isAdministrator,
  onCommandClick,
}: ViewerProps) => {
  const showSnackbar = useSnackbar();
  const { role } = useProfile();
  const { isConfirmed } = useConfirm();
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const { organizationSettings, dicomServiceTokenSettings } = useContext(ThemeContext);
  const dicomWebUrl = organizationSettings?.DicomWebServer || '';
  const loadedReportDataUrl = useRef<PdfLoadedEventData | null>(null);
  const {
    generatedReportIds,
    isError,
    isLoading: isLoadingGeneratedReports,
    error,
  } = useGeneratedReports(studyInstanceUid);
  const { reportIds: reportsCreatedByUserIds, isLoading: isLoadingReportsCreatedByUser } =
    useGetReportsCreatedByUser(studyInstanceUid);
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const [hasViewerInitialized, setHasViewerInitialized] = useState(false);
  const viewerRef = useRef<HTMLDivElement>(null);
  const reportRef = useRef<HTMLDivElement>(null);
  const {
    showSideReport,
    handleOpenSideReport,
    handleUploadReport,
    handleCancelSideReport,
    isUploading,
  } = useViewerSideReportFunctionality({
    studyInstanceUid,
    viewerRef,
    reportRef,
  });
  const sx = useSx({ showSideReport });

  const viewerConfig = useMemo(
    () =>
      config({
        studyInstanceUid,
        isAnon,
        isAdministrator,
        role,
        routerBasename: baseUrl,
        reportsCreatedByUser: reportsCreatedByUserIds || [],
        deleteSeriesHandler: (displaySet, existingSeriesCount) =>
          deleteSeriesHandler(
            displaySet,
            existingSeriesCount,
            (message) => showSnackbar({ title: 'Eliminado', message: message }),
            isConfirmed,
          ),
        generatedReportIds: isError || readOnly ? [] : generatedReportIds,
      }),
    [
      studyInstanceUid,
      readOnly,
      isAnon,
      baseUrl,
      showSnackbar,
      role,
      isError,
      isConfirmed,
      generatedReportIds,
      dicomWebUrl,
      reportsCreatedByUserIds,
    ],
  );

  const printPhoto = useCallback(
    async ($e: Event) => {
      setIsActionInProgress(true);
      const printPhotoEvent = $e as CustomEvent<{ activeViewportIndex: number }>;
      // Obtener el elemento del DOM que se desea capturar
      const elements = document.querySelectorAll('.viewport-wrapper');
      const elemento = elements[printPhotoEvent.detail.activeViewportIndex] as HTMLElement;

      const canvas = await html2canvas(elemento);

      canvas.toBlob(async function (blob) {
        navigator.clipboard
          .write([
            new ClipboardItem({
              'image/png': blob!,
            }),
          ])
          .then(function () {
            showSnackbar({
              title: 'Imagen copiada exitosamente.',
              message: 'La captura se ha copiado al portapapeles correctamente.',
              type: 'success',
            });
          })
          .catch(function () {
            showSnackbar({
              title: 'Error al copiar imagen',
              message: 'Ha ocurrido un error al intentar copiar la captura al portapapeles.',
              type: 'error',
            });
          });
      }, 'image/png');
      setIsActionInProgress(false);
    },
    [showSnackbar],
  );

  const handleCommandClick = useCallback(($e: Event) => {
    const { detail } = $e as CustomEvent<CommandArguments>;
    onCommandClick?.(detail);
  }, []);

  useEffect(() => {
    if (isLoadingGeneratedReports && isLoadingReportsCreatedByUser && !readOnly) {
      return;
    }

    if (isError) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error while loading generated report IDs', error);
      }
    }

    if (!dicomWebUrl) {
      console.error('DICOM Web URL is not configured correctly.');
      return;
    }

    const updatedViewerConfig = {
      ...viewerConfig,
      servers: {
        dicomWeb: viewerConfig.servers.dicomWeb.map((server) => ({
          ...server,
          wadoRoot: dicomWebUrl,
          qidoRoot: dicomWebUrl,
          wadoUriRoot: dicomWebUrl,
          delowRoot: dicomWebUrl,
        })),
      },
    };

    const handleReportUpload = (event: Event) => {
      event.preventDefault();
      // TODO: report back the report to OHIF viewer
      window.location.reload();
    };

    const handleToolbarResize = ($e: Event) => {
      const { detail } = $e as CustomEvent<ResizeObserverEntry>;
      const [{ blockSize } = { blockSize: 0 }] = detail.borderBoxSize;
      document.documentElement.style.setProperty('--ohif-toolbar-height', `${blockSize ?? '78'}px`); // 78px is the default
    };

    const handlePdfLoaded = ($e: Event) => {
      const pdfLoadedEvent = $e as CustomEvent<PdfLoadedEventData | null>;
      loadedReportDataUrl.current = pdfLoadedEvent.detail;
    };

    const handleReportDownload = ($e: Event) => {
      if (!loadedReportDataUrl.current) {
        return;
      }

      const { pdfUrl, sopInstanceUID } = loadedReportDataUrl.current;
      const reportEvent = $e as CustomEvent<ReportEventData>;
      if (reportEvent.detail.objectId !== sopInstanceUID) {
        return;
      }

      const a = document.createElement('a');
      a.href = pdfUrl;
      a.download = pdfUrl.substr(pdfUrl.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    const handleReportPrint = ($e: Event) => {
      if (!loadedReportDataUrl.current) {
        return;
      }

      const { pdfUrl, sopInstanceUID } = loadedReportDataUrl.current;
      const reportEvent = $e as CustomEvent<ReportEventData>;
      if (reportEvent.detail.objectId !== sopInstanceUID) {
        return;
      }

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = pdfUrl;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow!.focus();
        iframe.contentWindow!.print();
      };

      window.onfocus = () => {
        setTimeout(() => {
          document.body.removeChild(iframe);
          window.onfocus = null;
        }, 100);
      };
    };

    const handleReportPrintNoTemplate = async ($e: Event) => {
      setIsActionInProgress(true);
      if (!loadedReportDataUrl.current) {
        return;
      }

      const { sopInstanceUID } = loadedReportDataUrl.current;
      const reportEvent = $e as CustomEvent<ReportEventData>;
      if (reportEvent.detail.objectId !== sopInstanceUID) {
        return;
      }
      // todo
      const { studyId, objectId } = reportEvent.detail;
      try {
        const report = await getPrintableReport(studyId, objectId);
        const reportUrl = URL.createObjectURL(report);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = reportUrl;
        document.body.appendChild(iframe);

        iframe.onload = () => {
          iframe.contentWindow!.focus();
          iframe.contentWindow!.print();
        };

        window.onfocus = () => {
          setTimeout(() => {
            document.body.removeChild(iframe);
            window.onfocus = null;
            URL.revokeObjectURL(reportUrl);
          }, 100);
        };
      } catch (testError: any) {
        console.log({ testError });
      } finally {
        setIsActionInProgress(false);
      }
    };

    const handleReportAdded = async () => {
      // invalidate queries associated with reports
      await queryClient.invalidateQueries([GENERATED_REPORTS_QUERY_KEY]);
    };

    const hasLoadedDataForViewer =
      dicomServiceTokenSettings?.token &&
      !isLoadingReportsCreatedByUser &&
      !isLoadingGeneratedReports;

    const hasLoadedDataForReadOnlyViewer = dicomServiceTokenSettings?.token && readOnly;

    if (hasLoadedDataForViewer || hasLoadedDataForReadOnlyViewer) {
      installViewer(updatedViewerConfig, containerId, () => {
        setHasViewerInitialized(true);
        document.addEventListener('evodicom.command.click', handleCommandClick);
        document.addEventListener('evodicom.toolbar.resize', handleToolbarResize);
        document.addEventListener('evodicom.report.upload', handleReportUpload);
        document.addEventListener('evodicom.pdf.loaded', handlePdfLoaded);
        document.addEventListener('evodicom.report.download', handleReportDownload);
        document.addEventListener('evodicom.report.print', handleReportPrint);
        document.addEventListener('evodicom.report.printNoTemplate', handleReportPrintNoTemplate);
        document.addEventListener('evodicom.report.added', handleReportAdded);
        document.addEventListener('evodicom.handleSideReport', handleOpenSideReport);
        document.addEventListener('evodicom.printPhoto', printPhoto);
        document.addEventListener('evodicom.changeLoadingState', async ($e: Event) => {
          const loadingEvent = $e as CustomEvent<{ loading: boolean }>;
          setIsActionInProgress(loadingEvent.detail.loading);
        });
      });
    }

    return () => {
      document.removeEventListener('evodicom.command.click', handleCommandClick);
      document.removeEventListener('evodicom.toolbar.resize', handleToolbarResize);
      document.removeEventListener('evodicom.report.upload', handleReportUpload);
      document.removeEventListener('evodicom.pdf.loaded', handlePdfLoaded);
      document.removeEventListener('evodicom.report.download', handleReportDownload);
      document.removeEventListener('evodicom.report.print', handleReportPrint);
      document.removeEventListener('evodicom.report.printNoTemplate', handleReportPrintNoTemplate);
      document.removeEventListener('evodicom.report.added', handleReportAdded);
      document.removeEventListener('evodicom.handleSideReport', handleOpenSideReport);
      document.removeEventListener('evodicom.printPhoto', printPhoto);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onCommandClick,
    viewerConfig,
    isLoadingGeneratedReports,
    isError,
    dicomWebUrl,
    dicomServiceTokenSettings,
    printPhoto,
  ]);

  useEffect(() => {
    const section = searchParams.get('section');
    if (hasViewerInitialized) hideStartLoader({ isAuthenticated: true });
    if (hasViewerInitialized && section && !readOnly) {
      onCommandClick?.({ commandName: section as ViewerInfoCommand });
    }
  }, [hasViewerInitialized, onCommandClick, readOnly, searchParams]);

  return (
    <Box id="viewer-container" sx={sx.root} display="flex">
      <Box ref={viewerRef} position="relative" className="viewer" id={containerId} />
      <If condition={showSideReport}>
        <Box
          minWidth="40%"
          width="40%"
          id="report"
          maxHeight="calc(100vh - 64px)"
          overflow="auto"
          paddingLeft="4px"
          paddingY="20px"
          ref={reportRef}
        >
          <Form
            handleReportSubmit={handleUploadReport}
            initForm={{
              name: '',
            }}
            handleCancel={handleCancelSideReport}
            isLoading={isUploading}
            studyId={studyInstanceUid}
            smallView
          />
        </Box>
      </If>

      <SoftLoading isOpen={isActionInProgress} />
    </Box>
  );
};

export default Viewer;
