import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import QRCode from 'qrcode';
import { Editor } from 'roosterjs-editor-core';
import { EditorPlugin } from 'roosterjs-editor-types';
import { Ribbon, RibbonPlugin, createRibbonPlugin } from 'roosterjs-react';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import ClearIcon from '@mui/icons-material/Clear';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import ListIcon from '@mui/icons-material/List';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ActionButton from '@components/Button/ActionButton';
import IconButton from '@components/Button/IconButton';
import FullScreenLoader from '@components/FullScreenLoader';
import If from '@components/If';
import { subscribe, unsubscribe } from '@core/constants/customEvents';
import useProfile from '@core/hooks/useProfile';
import useSnackbar from '@core/hooks/useSnackbar';
import useViewportSize from '@core/hooks/useViewportSize';
import getTemplateTags from '@core/utils/getTemplateTags';
import isCustomErroHandlerResponse from '@core/utils/isCustomErrorHandlerResponse';
import PreviewTextEditor from 'app/features/templates/add/components/FormDialog/PreviewTextEditor';
import {
  handlePageSplitting,
  pageConfig,
} from 'app/features/templates/add/components/FormDialog/PreviewTextEditor/Preview/Preview';
import useAddTemplate from 'app/features/templates/add/hooks/useAddTemplate';
import replacePlaceholders from 'app/features/templates/add/utils/replacePlaceholders';
import useGetSingleTemplate from 'app/features/templates/edit/hooks/useGetSingleTemplate';
import { useGetSignature } from 'app/features/users/edit/hooks/useSignature';
import { initialSignature, initialTemplate } from '../../constants/initialTemplate';
import useGetDicomTemplateTagsInfo from '../../hooks/useGetDicomTemplateTagsInfo';
import useGetFilteredButtons from '../../hooks/useGetFilteredButtons';
import replaceTemplateTags from '../../utils/replaceTemplateTags';
import AddTemplateDialog from './AddTemplateDialog';
import FolderViewDialog from './FolderViewDialog';
import FormDialogProps from './FormProps';
import RecordAudioAction from './RecordAudioAction';
import TemplateDropdown from './TemplateDropdown';
import TemplateTagsDialog from './TemplateTagsDialog';
import TextEditor from './TextEditor';
import TranslatorButton from './TranslatorButton';
import UploadAudioAction from './UploadAudioAction';
import { TextEditorMethods } from './documentTypes';
import validationSchema from './formValidation';
import useFormState from './useFormState';

const ADD_TEMPLATE_ERROR_MESSAGE =
  'La plantilla no se pudo agregar. Por favor, asegúrate de que el título sea único y verifica que el reporte tenga contenido para poder guardarlo como plantilla';

interface FormValues {
  name: string;
}

function getFormatedText(text: string) {
  const HTMLPages = JSON.parse(text) as string[];
  return HTMLPages.join('');
}

const Form = ({
  handleReportSubmit,
  initForm,
  isLoading,
  studyId,
  handleCancel,
  encodedDocument,
  smallView = false,
}: FormDialogProps) => {
  const {
    sx,
    isLoadingShareUrl,
    templateId,
    templateImageUrl,
    isTemplateImageLoading,
    study,
    isTranscribing,
    setIsTranscribing,
    organizationTemplates,
  } = useFormState(studyId);
  const initialValues = {
    selectedTemplate: '',
    name: initForm?.name || '',
  };
  const textEditorRef = useRef<TextEditorMethods>(null);
  const editorRef = useRef<Editor>(null);
  const [ribbonPlugin, setRibbonPlugin] = useState(createRibbonPlugin());
  const ribbonPlugins = useRef<Array<RibbonPlugin>>([ribbonPlugin]);
  const showSnackbar = useSnackbar();
  const { isUpMedium } = useViewportSize();

  const { fullName } = useProfile();
  const { data } = useGetSignature();

  const getDicomValue = useGetDicomTemplateTagsInfo({ study });

  const [variables, setVariables] = useState(getTemplateTags(initialTemplate));

  const formikRef = useRef<
    FormikProps<{
      selectedTemplate: string;
      name: string;
    }>
  >(null);

  const [dialog, setDialog] = useState<'TEMPLATES' | 'ADD_TEMPLATE' | 'FOLDER_VIEW' | null>(null);
  const [storedText, setStoredText] = useState('');
  const [roosterWithPreview, setRoosterWithPreview] = useState<boolean>(
    window.localStorage.getItem('roosterWithPreview') === 'true',
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const { template, isLoading: isSingleTemplateLoading } = useGetSingleTemplate(
    selectedTemplateId || '',
  );
  const [qrSrc, setQrSrc] = useState('');

  const initialText = useMemo(() => {
    const temp = document.createElement('div');
    temp.innerHTML = initialTemplate + initialSignature;
    replacePlaceholders(temp, {
      professionalFullName: fullName,
      professionalInfo: data?.professionalInfo,
      signatureBase64: data?.signature,
      shareStudyQrBase64: qrSrc,
      shareStudyUrl: study?.shareUrl,
    });
    return temp.innerHTML;
  }, [data?.professionalInfo, fullName, qrSrc, data?.signature, study?.shareUrl]);

  useEffect(() => {
    // Here we keep the anchorImage on memory
    async function generateAnchorImage() {
      if (study?.shareUrl) {
        // Save the QR as base 64 to replace on corresponding placeholder
        const qrCode = document.createElement('canvas');
        const qrCodeBase64 = await QRCode.toDataURL(qrCode, study.shareUrl);
        setQrSrc(qrCodeBase64);
      }
    }
    generateAnchorImage();
  }, [study?.shareUrl]);

  // ribbon related state
  const theme = useTheme();
  const { filteredButtons } = useGetFilteredButtons(
    theme.palette.mode,
    study!,
    organizationTemplates?.items || [],
  );

  const createPluginsForPage = (pageNumber: number): Array<EditorPlugin> => {
    if (pageNumber >= ribbonPlugins.current.length) {
      ribbonPlugins.current[pageNumber] = createRibbonPlugin();
    }

    return [ribbonPlugins.current[pageNumber]];
  };

  const editorPageChange = (pageNumber: number, focusedNode?: Node) => {
    if (pageNumber >= ribbonPlugins.current.length) {
      return;
    }
    setRibbonPlugin(ribbonPlugins.current[pageNumber]);
  };

  const memoSignature = useMemo(() => {
    const temp = document.createElement('div');
    temp.innerHTML = initialSignature;

    replacePlaceholders(temp, {
      professionalFullName: fullName,
      professionalInfo: data?.professionalInfo,
      signatureBase64: data?.signature,
      shareStudyQrBase64: qrSrc,
      shareStudyUrl: study?.shareUrl,
    });

    return temp;
  }, [data?.signature, data?.professionalInfo, fullName, qrSrc, study?.shareUrl]);

  const handleSubmitFile = async (values: FormValues) => {
    if (!textEditorRef.current && !editorRef.current) {
      return;
    }
    const reportContent = roosterWithPreview
      ? editorRef.current!.getContent()
      : textEditorRef.current!.getContent();

    if (reportContent === null || reportContent?.length === 0) {
      showSnackbar({
        type: 'error',
        title: 'Ocurrió un error',
        message: 'No ha sido posible crear el reporte.',
      });
      return;
    }
    const documentConfiguration = pageConfig;

    const temp = document.createElement('div');
    if (roosterWithPreview) temp.innerHTML = reportContent as string;

    const report = {
      pages: roosterWithPreview
        ? handlePageSplitting(
            temp,
            pageConfig.size.height - pageConfig.padding.top - pageConfig.padding.bottom,
          )
        : (reportContent as string[]),

      configuration: documentConfiguration,
      organizationTemplateId: templateId.length > 0 ? templateId : null,
      title: values.name,
    };

    await handleReportSubmit(report);
  };

  const handleUpdateTextEditorContent = async (newContent: string) => {
    if ((textEditorRef.current || editorRef.current) && newContent) {
      try {
        if (roosterWithPreview) editorRef.current?.setContent(newContent);
        else textEditorRef.current?.setContent(newContent);
      } catch (error) {
        console.error('Error updating editor content:', error);
      }
    }
  };

  function handleTextEditorChange() {
    const tempDiv = document.createElement('div');
    const content = roosterWithPreview
      ? editorRef.current?.getContent()
      : textEditorRef.current?.getContent().join('');

    tempDiv.innerHTML = content || '';

    setStoredText(tempDiv.innerHTML);
    setRoosterWithPreview(!roosterWithPreview);
  }

  const onTemplateSelectConfirm = (newTemplateId: string) => {
    setSelectedTemplateId(newTemplateId);
    setDialog('TEMPLATES');
  };

  const { addTemplate, isUploading } = useAddTemplate();

  const onTemplateAdd = async (values: {
    folderName: string;
    title: string;
    organizationWide: boolean;
    templateFolderId?: number;
  }) => {
    const reportContent = roosterWithPreview
      ? editorRef.current?.getContent()
      : textEditorRef.current?.getContent().join('');

    if (reportContent) {
      try {
        const doc = document.createElement('div');
        doc.innerHTML = reportContent;

        doc.childNodes.forEach((node) => {
          const element = node as HTMLElement;
          if (element.style) {
            (element as HTMLElement).style.color = '';
          }
        });

        if (doc.innerText.length === 0) throw new Error('El reporte debe tener contenido');

        const response = await addTemplate({
          templateName: values.title,
          templateFolderId: values.templateFolderId,
          content: doc.innerHTML,
          organizationWide: values.organizationWide,
        });

        if (isCustomErroHandlerResponse(response))
          throw new Error('Error al intentar agregar la plantilal');

        setDialog(null);
        showSnackbar({
          type: 'success',
          title: 'Plantilla agregada',
          message: 'La plantilla se ha añadido con éxito.',
        });
      } catch (err: any) {
        console.log(err.message);
        showSnackbar({
          type: 'error',
          title: 'Error al agregar la plantilla',
          message: ADD_TEMPLATE_ERROR_MESSAGE,
        });
      }
    }
  };

  const onMountTextEditor = () => {
    if (encodedDocument?.length) {
      textEditorRef.current?.setHTMLTemplateContent(getFormatedText(encodedDocument));
    } else {
      const newHtml = storedText.length
        ? storedText
        : replaceTemplateTags(initialText, variables, getDicomValue);

      if (!roosterWithPreview) textEditorRef.current?.setHTMLTemplateContent(newHtml);
    }
  };

  useEffect(() => {
    Object.keys(variables).forEach((key) => {
      if (getDicomValue(key)) {
        variables[key] = getDicomValue(key);
      }
    });
    setVariables(variables);
  }, [study]);

  useEffect(() => {
    const addInitialTemplate = () => {
      if (roosterWithPreview) {
        const html = editorRef.current?.getContent();

        const htmlWithReplacedTags = replaceTemplateTags(initialTemplate, variables, getDicomValue);
        editorRef.current?.setContent(htmlWithReplacedTags + html);
      } else {
        const html = textEditorRef.current?.getContent();

        const htmlWithReplacedTags = replaceTemplateTags(initialTemplate, variables, getDicomValue);
        textEditorRef.current?.setHTMLTemplateContent(htmlWithReplacedTags + html);
      }
    };

    function addSignature() {
      if (roosterWithPreview) {
        const html = editorRef.current?.getContent();
        editorRef.current?.setContent(html + memoSignature.innerHTML);
      } else {
        const html = textEditorRef.current?.getContent();
        textEditorRef.current?.setHTMLTemplateContent(html + memoSignature.innerHTML);
      }
    }
    const ref = subscribe('evodicom.report.initialTemplate', addInitialTemplate);
    const ref2 = subscribe('evodicom.report.insertQrCode', addSignature);
    return () => {
      unsubscribe('evodicom.report.initialTemplate', ref);
      unsubscribe('evodicom.report.insertQrCode', ref2);
    };
  }, [roosterWithPreview, memoSignature]);

  useEffect(() => {
    window.localStorage.setItem('roosterWithPreview', `${roosterWithPreview}`);
  }, [roosterWithPreview]);

  const changeDialogState = (dialogState: typeof dialog) => () => {
    setDialog(dialogState);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitFile}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ values, handleSubmit, handleChange, errors, resetForm, isValid }) => {
          return (
            <>
              <Box
                flexDirection="column"
                display="flex"
                gap={'1rem'}
                style={{
                  position: 'fixed',
                  maxWidth: 'inherit',
                  width: 'inherit',
                  zIndex: 1000,
                  backgroundColor: theme.palette.background.default,
                  top: '64px',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                }}
              >
                <Box sx={sx.inputs}>
                  <Box flex={1} className="input-container">
                    <TextField
                      placeholder="Titulo"
                      name="name"
                      sx={{ width: '100%' }}
                      value={values.name}
                      onChange={handleChange}
                      error={!!errors.name}
                      helperText={errors.name}
                      inputProps={{ sx: sx.inputName }}
                    />
                  </Box>
                  <If condition={isUpMedium}>
                    <Box flex={!smallView ? 1 : 0} className="input-container">
                      <If
                        condition={!smallView}
                        fallback={
                          <ActionButton
                            sx={{ marginLeft: 'auto' }}
                            variant="contained"
                            text="Buscar Plantilla"
                            onClick={changeDialogState('FOLDER_VIEW')}
                          />
                        }
                      >
                        <TemplateDropdown
                          onSeeMoreTemplates={changeDialogState('FOLDER_VIEW')}
                          onCancel={() => {}}
                          onConfirm={onTemplateSelectConfirm}
                        />
                      </If>
                    </Box>
                  </If>
                  <Box sx={sx.topButtons}>
                    <If condition={!isUpMedium}>
                      <IconButton
                        tooltip="Abrir opciones"
                        variant="contained"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        <ListIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={() => {
                          setAnchorEl(null);
                        }}
                      >
                        <MenuItem onClick={changeDialogState('ADD_TEMPLATE')}>
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText>Plantillas</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleTextEditorChange}>
                          <ListItemIcon>
                            <FormatAlignCenterIcon />
                          </ListItemIcon>
                          <ListItemText>Cambiar Editor</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={changeDialogState('FOLDER_VIEW')}>
                          <ListItemIcon>
                            <ArticleIcon />
                          </ListItemIcon>
                          <ListItemText>Buscar Plantillas</ListItemText>
                        </MenuItem>
                      </Menu>
                    </If>
                    <If
                      condition={isUpMedium}
                      fallback={
                        <IconButton
                          onClick={() => {
                            handleCancel();
                            resetForm();
                          }}
                          tooltip="Cancelar"
                          color="secondary"
                          variant="outlined"
                        >
                          <ClearIcon />
                        </IconButton>
                      }
                    >
                      <>
                        <ActionButton
                          onClick={handleTextEditorChange}
                          text="Cambiar editor"
                          variant="contained"
                        />
                        <ActionButton
                          text="Plantillas"
                          startIcon={<AddIcon />}
                          variant="outlined"
                          color="primary"
                          onClick={changeDialogState('ADD_TEMPLATE')}
                          disabled={!isValid}
                          isLoading={isLoading}
                        />
                        <ActionButton
                          onClick={() => {
                            handleCancel();
                            resetForm();
                          }}
                          text="Cancelar"
                          variant="outlined"
                          color="secondary"
                        />
                      </>
                    </If>
                    <ActionButton
                      text="Guardar"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={!isValid}
                      isLoading={isLoading}
                    />
                  </Box>
                </Box>
                <If condition={!roosterWithPreview}>
                  <Ribbon
                    buttons={filteredButtons}
                    plugin={ribbonPlugin}
                    overflowButtonProps={{
                      menuProps: {
                        className:
                          theme.palette.mode === 'light'
                            ? 'dropdown-menu--light'
                            : 'dropdown-menu--dark',
                        items: [],
                      },
                    }}
                  />
                </If>
              </Box>

              <If condition={!roosterWithPreview && !isLoadingShareUrl && !isTemplateImageLoading}>
                <Box
                  sx={sx.root}
                  position={'relative'}
                  maxWidth="inherit"
                  width="100%"
                  component="form"
                >
                  <TextEditor
                    backgroundUrl={templateImageUrl}
                    ref={textEditorRef}
                    createPluginsForEditor={createPluginsForPage}
                    handlePageChange={editorPageChange}
                    onMount={onMountTextEditor}
                  />
                </Box>
              </If>
              <If condition={roosterWithPreview && !!study}>
                <Box sx={{ marginTop: '3rem' }}>
                  <PreviewTextEditor
                    initialContent={
                      encodedDocument?.length
                        ? getFormatedText(encodedDocument)
                        : replaceTemplateTags(
                            storedText.length ? storedText : initialText,
                            variables,
                            getDicomValue,
                          )
                    }
                    textEditorRef={editorRef}
                    error={false}
                    helperText={'errors.content'}
                    hidePreview={smallView}
                  />
                </Box>
              </If>
            </>
          );
        }}
      </Formik>
      <FolderViewDialog
        isOpen={dialog === 'FOLDER_VIEW'}
        onClose={changeDialogState(null)}
        onTemplateClick={(templateFolder) => {
          onTemplateSelectConfirm(templateFolder.folderHierarchyItemId.toString());
        }}
      />
      <TranslatorButton
        textEditorRef={roosterWithPreview ? editorRef : textEditorRef}
        isPreviewEditor={roosterWithPreview}
      />
      <If condition={!!template}>
        <TemplateTagsDialog
          onHTMLReady={(newHtml) => {
            const temp = document.createElement('div');
            temp.innerHTML = newHtml;
            replacePlaceholders(temp, {
              professionalFullName: fullName,
              professionalInfo: data?.professionalInfo,
              signatureBase64: data?.signature,
              shareStudyQrBase64: qrSrc,
              shareStudyUrl: study?.shareUrl,
            });

            if (roosterWithPreview) editorRef.current?.setContent(temp.innerHTML);
            else textEditorRef.current?.setHTMLTemplateContent(temp.innerHTML);
          }}
          isOpen={dialog === 'TEMPLATES'}
          setIsOpen={setDialog}
          template={template}
          study={study}
          isSingleTemplateLoading={isSingleTemplateLoading}
        />
      </If>
      <If condition={isTranscribing}>
        <FullScreenLoader transparent />
      </If>
      <RecordAudioAction
        onUpdateTextEditor={handleUpdateTextEditorContent}
        isTranscribing={isTranscribing}
        setIsTranscribing={setIsTranscribing}
      />
      <UploadAudioAction
        onUpdateTextEditor={handleUpdateTextEditorContent}
        isTranscribing={isTranscribing}
        setIsTranscribing={setIsTranscribing}
      />
      <AddTemplateDialog
        isLoading={isUploading}
        onAccept={onTemplateAdd}
        onCancel={changeDialogState(null)}
        open={dialog === 'ADD_TEMPLATE'}
      />
    </>
  );
};

export default Form;
